<template>
  <v-dialog v-model="dialog" max-width="456" class="dialog-container">
    <template v-slot:activator="{ on, attrs }">
      <div v-bind="attrs" v-on="on"><slot></slot></div>
    </template>

    <v-card class="dialog-card-container" :key="dialogKey">
      <h2 class="dialog-card-title text-center">
        {{ title }}
      </h2>

      <div class="dialog-card-content"><slot name="content"></slot></div>
      <div class="dialog-card-action" @click="dialog = false">
        <slot name="actions"></slot>
      </div>
    </v-card>
  </v-dialog>
</template>
<script>
/**
 * Dialog component
 * @note need to register inqli-dialog name as dialog only does not work
 */
export default {
  name: "InqliDialog",
  props: {
    title: String,
    dialogKey: String,
  },
  data() {
    return {
      dialog: false,
    };
  },
};
</script>
<style lang="scss" scoped>
.dialog-container {
  border-radius: 16px;
}
.dialog-card-container {
  border-radius: 16px;
  padding: 32px 16px;
  border: 1px solid $inqliGray;
  .dialog-card-content {
    margin: auto;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}
.dialog-card-title {
  font-size: 18px;
  font-weight: 800;
}
.dialog-card-action {
  display: flex;
  justify-content: center;
  width: 100%;
  .v-btn {
    display: block;
  }
}
</style>
