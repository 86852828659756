<template>
  <v-text-field
    class="copy-text-field"
    dense
    outlined
    :value="copyValue"
    :hint="message"
    persistent-hint
    readonly
  >
    <template #append>
      <v-tooltip top open-on-hover nudge-top="4">
        <template v-slot:activator="{ on, attrs }">
          <v-icon dense v-bind="attrs" v-on="on" @click="copyTextToClipBoard"
            >mdi-content-copy</v-icon
          >
        </template>
        <span>Copy</span>
      </v-tooltip>
    </template>
  </v-text-field>
</template>
<script>
export default {
  name: "CopyToClipBoard",
  props: {
    copyValue: String,
  },
  data() {
    return {
      message: "",
    };
  },
  mounted() {
    if (!navigator.clipboard || !window.isSecureContext) {
      // if can not use navigator.clipboard or not in secure context
      // show message for user to copy manually
      this.message = "Please select and copy manually.";
    }
  },
  methods: {
    copyTextToClipBoard() {
      // navigator clipboard api needs a secure context (https)
      if (navigator.clipboard && window.isSecureContext) {
        navigator.clipboard.writeText(this.copyValue);
        this.message = "Copied to clipboard";
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
