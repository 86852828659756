var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      staticClass: "dialog-container",
      attrs: { "max-width": "456" },
      scopedSlots: _vm._u(
        [
          {
            key: "activator",
            fn: function(ref) {
              var on = ref.on
              var attrs = ref.attrs
              return [
                _c(
                  "div",
                  _vm._g(_vm._b({}, "div", attrs, false), on),
                  [_vm._t("default")],
                  2
                )
              ]
            }
          }
        ],
        null,
        true
      ),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-card",
        { key: _vm.dialogKey, staticClass: "dialog-card-container" },
        [
          _c("h2", { staticClass: "dialog-card-title text-center" }, [
            _vm._v(" " + _vm._s(_vm.title) + " ")
          ]),
          _c(
            "div",
            { staticClass: "dialog-card-content" },
            [_vm._t("content")],
            2
          ),
          _c(
            "div",
            {
              staticClass: "dialog-card-action",
              on: {
                click: function($event) {
                  _vm.dialog = false
                }
              }
            },
            [_vm._t("actions")],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }