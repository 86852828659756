<template>
  <inqli-dialog title="Share with your friends" :dialogKey="shareUrl">
    <v-tooltip
      :top="toolTipPosition.top"
      :bottom="toolTipPosition.bottom"
      :left="toolTipPosition.left"
      :right="toolTipPosition.right"
      open-on-hover
      nudge-top="4"
    >
      <template v-slot:activator="{ on, attrs }">
        <!-- <v-icon
          :small="small"
          v-bind="attrs"
          v-on="on"
          class="share-button"
          aria-label="share-link-dialog"
          color="inqliPurple"
          >mdi-export-variant</v-icon
        > -->
        <span
          class="material-symbols-rounded share-button"
          v-bind="attrs"
          v-on="on"
          aria-label="share-link-dialog"
        >
          ios_share
        </span>
      </template>
      <span>Share</span>
    </v-tooltip>
    <template #content>
      <copy-to-clip-board :copyValue="shareUrl" class="ma-auto" />
    </template>
    <template #actions>
      <inqli-button>Done</inqli-button>
    </template>
  </inqli-dialog>
</template>
<script>
import Dialog from "./Dialog.vue";
import CopyToClipBoard from "../CopyToClipBoard.vue";
import Button from "../buttons/Button.vue";
export default {
  name: "ShareLinkDialog",
  components: {
    "inqli-dialog": Dialog,
    "copy-to-clip-board": CopyToClipBoard,
    "inqli-button": Button,
  },
  props: {
    shareUrl: {
      type: String,
      required: true,
    },
    toolTipPlace: {
      type: String,
      default: "top",
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    toolTipPosition() {
      const position = {
        top: false,
        bottom: false,
        left: false,
        right: false,
      };
      switch (this.toolTipPlace) {
        case "top":
          position.top = true;
          break;
        case "bottom":
          position.bottom = true;
          break;
        case "left":
          position.left = true;
          break;
        case "right":
          position.right = true;
      }
      return position;
    },
  },
};
</script>
<style lang="scss" scoped>
.share-button {
  font-weight: 600;
  font-size: 18px;
  padding-top: 3px;
  color: $inqliPurple;
}
</style>
