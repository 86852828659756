var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("v-text-field", {
    staticClass: "copy-text-field",
    attrs: {
      dense: "",
      outlined: "",
      value: _vm.copyValue,
      hint: _vm.message,
      "persistent-hint": "",
      readonly: ""
    },
    scopedSlots: _vm._u([
      {
        key: "append",
        fn: function() {
          return [
            _c(
              "v-tooltip",
              {
                attrs: { top: "", "open-on-hover": "", "nudge-top": "4" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function(ref) {
                      var on = ref.on
                      var attrs = ref.attrs
                      return [
                        _c(
                          "v-icon",
                          _vm._g(
                            _vm._b(
                              {
                                attrs: { dense: "" },
                                on: { click: _vm.copyTextToClipBoard }
                              },
                              "v-icon",
                              attrs,
                              false
                            ),
                            on
                          ),
                          [_vm._v("mdi-content-copy")]
                        )
                      ]
                    }
                  }
                ])
              },
              [_c("span", [_vm._v("Copy")])]
            )
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }