var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "inqli-dialog",
    {
      attrs: { title: "Share with your friends", dialogKey: _vm.shareUrl },
      scopedSlots: _vm._u([
        {
          key: "content",
          fn: function() {
            return [
              _c("copy-to-clip-board", {
                staticClass: "ma-auto",
                attrs: { copyValue: _vm.shareUrl }
              })
            ]
          },
          proxy: true
        },
        {
          key: "actions",
          fn: function() {
            return [_c("inqli-button", [_vm._v("Done")])]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "v-tooltip",
        {
          attrs: {
            top: _vm.toolTipPosition.top,
            bottom: _vm.toolTipPosition.bottom,
            left: _vm.toolTipPosition.left,
            right: _vm.toolTipPosition.right,
            "open-on-hover": "",
            "nudge-top": "4"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function(ref) {
                var on = ref.on
                var attrs = ref.attrs
                return [
                  _c(
                    "span",
                    _vm._g(
                      _vm._b(
                        {
                          staticClass: "material-symbols-rounded share-button",
                          attrs: { "aria-label": "share-link-dialog" }
                        },
                        "span",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v(" ios_share ")]
                  )
                ]
              }
            }
          ])
        },
        [_c("span", [_vm._v("Share")])]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }